@charset "UTF-8";
/* You can add global styles to this file, and also import other style files */
*, *::before, *::after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  height: 100vh;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: normal;
  font-size: 14px;
  color: #000;
  background-color: #f5f5f5;
  -webkit-font-smoothing: antialiased;
  -ms-overflow-style: none;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

a:link, a:visited, a:hover, a:focus, a:active {
  color: #0066FF;
  cursor: pointer;
}

.container {
  padding-left: 6px;
  padding-right: 6px;
  box-sizing: border-box;
  margin: 0 auto;
  max-width: 1200px;
}

.row {
  margin-left: -6px;
  margin-right: -6px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
  -webkit-flex: 0 1 auto;
  -moz-box-flex: 0;
  -moz-flex: 0 1 auto;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-direction: normal;
  -webkit-box-orient: horizontal;
  -webkit-flex-direction: row;
  -moz-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  box-sizing: border-box;
}
.row::after {
  clear: both;
  content: "";
  display: table;
}

.col {
  padding-left: 6px;
  padding-right: 6px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 100%;
  -moz-box-flex: 0;
  -moz-flex: 0 0 100%;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  box-sizing: border-box;
  max-width: 100%;
}

.xs-1 {
  -webkit-flex-basis: 8.3333333333%;
  -moz-flex-basis: 8.3333333333%;
  -ms-flex-preferred-size: 8.3333333333%;
  flex-basis: 8.3333333333%;
  max-width: 8.3333333333%;
}

.xs-2 {
  -webkit-flex-basis: 16.6666666667%;
  -moz-flex-basis: 16.6666666667%;
  -ms-flex-preferred-size: 16.6666666667%;
  flex-basis: 16.6666666667%;
  max-width: 16.6666666667%;
}

.xs-3 {
  -webkit-flex-basis: 25%;
  -moz-flex-basis: 25%;
  -ms-flex-preferred-size: 25%;
  flex-basis: 25%;
  max-width: 25%;
}

.xs-4 {
  -webkit-flex-basis: 33.3333333333%;
  -moz-flex-basis: 33.3333333333%;
  -ms-flex-preferred-size: 33.3333333333%;
  flex-basis: 33.3333333333%;
  max-width: 33.3333333333%;
}

.xs-5 {
  -webkit-flex-basis: 41.6666666667%;
  -moz-flex-basis: 41.6666666667%;
  -ms-flex-preferred-size: 41.6666666667%;
  flex-basis: 41.6666666667%;
  max-width: 41.6666666667%;
}

.xs-6 {
  -webkit-flex-basis: 50%;
  -moz-flex-basis: 50%;
  -ms-flex-preferred-size: 50%;
  flex-basis: 50%;
  max-width: 50%;
}

.xs-7 {
  -webkit-flex-basis: 58.3333333333%;
  -moz-flex-basis: 58.3333333333%;
  -ms-flex-preferred-size: 58.3333333333%;
  flex-basis: 58.3333333333%;
  max-width: 58.3333333333%;
}

.xs-8 {
  -webkit-flex-basis: 66.6666666667%;
  -moz-flex-basis: 66.6666666667%;
  -ms-flex-preferred-size: 66.6666666667%;
  flex-basis: 66.6666666667%;
  max-width: 66.6666666667%;
}

.xs-9 {
  -webkit-flex-basis: 75%;
  -moz-flex-basis: 75%;
  -ms-flex-preferred-size: 75%;
  flex-basis: 75%;
  max-width: 75%;
}

.xs-10 {
  -webkit-flex-basis: 83.3333333333%;
  -moz-flex-basis: 83.3333333333%;
  -ms-flex-preferred-size: 83.3333333333%;
  flex-basis: 83.3333333333%;
  max-width: 83.3333333333%;
}

.xs-11 {
  -webkit-flex-basis: 91.6666666667%;
  -moz-flex-basis: 91.6666666667%;
  -ms-flex-preferred-size: 91.6666666667%;
  flex-basis: 91.6666666667%;
  max-width: 91.6666666667%;
}

.xs-12 {
  -webkit-flex-basis: 100%;
  -moz-flex-basis: 100%;
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  max-width: 100%;
}

.xs-auto {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 0%;
  -moz-box-flex: 1;
  -moz-flex: 1 1 0%;
  -ms-flex: 1 1 0%;
  flex: 1 1 0%;
}

.xs-offset-1 {
  margin-left: 8.3333333333%;
}

.xs-offset-2 {
  margin-left: 16.6666666667%;
}

.xs-offset-3 {
  margin-left: 25%;
}

.xs-offset-4 {
  margin-left: 33.3333333333%;
}

.xs-offset-5 {
  margin-left: 41.6666666667%;
}

.xs-offset-6 {
  margin-left: 50%;
}

.xs-offset-7 {
  margin-left: 58.3333333333%;
}

.xs-offset-8 {
  margin-left: 66.6666666667%;
}

.xs-offset-9 {
  margin-left: 75%;
}

.xs-offset-10 {
  margin-left: 83.3333333333%;
}

.xs-offset-11 {
  margin-left: 91.6666666667%;
}

.xs-pull-1 {
  position: relative;
  right: 8.3333333333%;
}

.xs-pull-2 {
  position: relative;
  right: 16.6666666667%;
}

.xs-pull-3 {
  position: relative;
  right: 25%;
}

.xs-pull-4 {
  position: relative;
  right: 33.3333333333%;
}

.xs-pull-5 {
  position: relative;
  right: 41.6666666667%;
}

.xs-pull-6 {
  position: relative;
  right: 50%;
}

.xs-pull-7 {
  position: relative;
  right: 58.3333333333%;
}

.xs-pull-8 {
  position: relative;
  right: 66.6666666667%;
}

.xs-pull-9 {
  position: relative;
  right: 75%;
}

.xs-pull-10 {
  position: relative;
  right: 83.3333333333%;
}

.xs-pull-11 {
  position: relative;
  right: 91.6666666667%;
}

.xs-pull-12 {
  position: relative;
  right: 100%;
}

.xs-push-1 {
  position: relative;
  left: 8.3333333333%;
}

.xs-push-2 {
  position: relative;
  left: 16.6666666667%;
}

.xs-push-3 {
  position: relative;
  left: 25%;
}

.xs-push-4 {
  position: relative;
  left: 33.3333333333%;
}

.xs-push-5 {
  position: relative;
  left: 41.6666666667%;
}

.xs-push-6 {
  position: relative;
  left: 50%;
}

.xs-push-7 {
  position: relative;
  left: 58.3333333333%;
}

.xs-push-8 {
  position: relative;
  left: 66.6666666667%;
}

.xs-push-9 {
  position: relative;
  left: 75%;
}

.xs-push-10 {
  position: relative;
  left: 83.3333333333%;
}

.xs-push-11 {
  position: relative;
  left: 91.6666666667%;
}

.xs-push-12 {
  position: relative;
  left: 100%;
}

.xs-start {
  margin-right: auto;
}

.xs-center {
  margin-right: auto;
  margin-left: auto;
}

.xs-end {
  margin-left: auto;
}

.xs-start {
  margin-right: auto;
}

.xs-center {
  margin-right: auto;
  margin-left: auto;
}

.xs-end {
  margin-left: auto;
}

.xs-cell {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -moz-box-flex: 1;
  -moz-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

@media only screen and (min-width: 567px) {
  .sm-1 {
    -webkit-flex-basis: 8.3333333333%;
    -moz-flex-basis: 8.3333333333%;
    -ms-flex-preferred-size: 8.3333333333%;
    flex-basis: 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .sm-2 {
    -webkit-flex-basis: 16.6666666667%;
    -moz-flex-basis: 16.6666666667%;
    -ms-flex-preferred-size: 16.6666666667%;
    flex-basis: 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .sm-3 {
    -webkit-flex-basis: 25%;
    -moz-flex-basis: 25%;
    -ms-flex-preferred-size: 25%;
    flex-basis: 25%;
    max-width: 25%;
  }
  .sm-4 {
    -webkit-flex-basis: 33.3333333333%;
    -moz-flex-basis: 33.3333333333%;
    -ms-flex-preferred-size: 33.3333333333%;
    flex-basis: 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .sm-5 {
    -webkit-flex-basis: 41.6666666667%;
    -moz-flex-basis: 41.6666666667%;
    -ms-flex-preferred-size: 41.6666666667%;
    flex-basis: 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .sm-6 {
    -webkit-flex-basis: 50%;
    -moz-flex-basis: 50%;
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    max-width: 50%;
  }
  .sm-7 {
    -webkit-flex-basis: 58.3333333333%;
    -moz-flex-basis: 58.3333333333%;
    -ms-flex-preferred-size: 58.3333333333%;
    flex-basis: 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .sm-8 {
    -webkit-flex-basis: 66.6666666667%;
    -moz-flex-basis: 66.6666666667%;
    -ms-flex-preferred-size: 66.6666666667%;
    flex-basis: 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .sm-9 {
    -webkit-flex-basis: 75%;
    -moz-flex-basis: 75%;
    -ms-flex-preferred-size: 75%;
    flex-basis: 75%;
    max-width: 75%;
  }
  .sm-10 {
    -webkit-flex-basis: 83.3333333333%;
    -moz-flex-basis: 83.3333333333%;
    -ms-flex-preferred-size: 83.3333333333%;
    flex-basis: 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .sm-11 {
    -webkit-flex-basis: 91.6666666667%;
    -moz-flex-basis: 91.6666666667%;
    -ms-flex-preferred-size: 91.6666666667%;
    flex-basis: 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .sm-12 {
    -webkit-flex-basis: 100%;
    -moz-flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    max-width: 100%;
  }
  .sm-auto {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 0%;
    -moz-box-flex: 1;
    -moz-flex: 1 1 0%;
    -ms-flex: 1 1 0%;
    flex: 1 1 0%;
  }
  .sm-offset-1 {
    margin-left: 8.3333333333%;
  }
  .sm-offset-2 {
    margin-left: 16.6666666667%;
  }
  .sm-offset-3 {
    margin-left: 25%;
  }
  .sm-offset-4 {
    margin-left: 33.3333333333%;
  }
  .sm-offset-5 {
    margin-left: 41.6666666667%;
  }
  .sm-offset-6 {
    margin-left: 50%;
  }
  .sm-offset-7 {
    margin-left: 58.3333333333%;
  }
  .sm-offset-8 {
    margin-left: 66.6666666667%;
  }
  .sm-offset-9 {
    margin-left: 75%;
  }
  .sm-offset-10 {
    margin-left: 83.3333333333%;
  }
  .sm-offset-11 {
    margin-left: 91.6666666667%;
  }
  .sm-pull-1 {
    position: relative;
    right: 8.3333333333%;
  }
  .sm-pull-2 {
    position: relative;
    right: 16.6666666667%;
  }
  .sm-pull-3 {
    position: relative;
    right: 25%;
  }
  .sm-pull-4 {
    position: relative;
    right: 33.3333333333%;
  }
  .sm-pull-5 {
    position: relative;
    right: 41.6666666667%;
  }
  .sm-pull-6 {
    position: relative;
    right: 50%;
  }
  .sm-pull-7 {
    position: relative;
    right: 58.3333333333%;
  }
  .sm-pull-8 {
    position: relative;
    right: 66.6666666667%;
  }
  .sm-pull-9 {
    position: relative;
    right: 75%;
  }
  .sm-pull-10 {
    position: relative;
    right: 83.3333333333%;
  }
  .sm-pull-11 {
    position: relative;
    right: 91.6666666667%;
  }
  .sm-pull-12 {
    position: relative;
    right: 100%;
  }
  .sm-push-1 {
    position: relative;
    left: 8.3333333333%;
  }
  .sm-push-2 {
    position: relative;
    left: 16.6666666667%;
  }
  .sm-push-3 {
    position: relative;
    left: 25%;
  }
  .sm-push-4 {
    position: relative;
    left: 33.3333333333%;
  }
  .sm-push-5 {
    position: relative;
    left: 41.6666666667%;
  }
  .sm-push-6 {
    position: relative;
    left: 50%;
  }
  .sm-push-7 {
    position: relative;
    left: 58.3333333333%;
  }
  .sm-push-8 {
    position: relative;
    left: 66.6666666667%;
  }
  .sm-push-9 {
    position: relative;
    left: 75%;
  }
  .sm-push-10 {
    position: relative;
    left: 83.3333333333%;
  }
  .sm-push-11 {
    position: relative;
    left: 91.6666666667%;
  }
  .sm-push-12 {
    position: relative;
    left: 100%;
  }
  .sm-start {
    margin-right: auto;
  }
  .sm-center {
    margin-right: auto;
    margin-left: auto;
  }
  .sm-end {
    margin-left: auto;
  }
  .sm-top {
    -webkit-align-self: flex-start;
    -moz-align-self: flex-start;
    -ms-flex-item-align: start;
    align-self: flex-start;
  }
  .sm-middle {
    -webkit-align-self: center;
    -moz-align-self: center;
    -ms-flex-item-align: center;
    align-self: center;
  }
  .sm-bottom {
    -webkit-align-self: flex-end;
    -moz-align-self: flex-end;
    -ms-flex-item-align: end;
    align-self: flex-end;
  }
  .sm-cell {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -moz-box-flex: 1;
    -moz-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
}
@media only screen and (min-width: 768px) {
  .md-1 {
    -webkit-flex-basis: 8.3333333333%;
    -moz-flex-basis: 8.3333333333%;
    -ms-flex-preferred-size: 8.3333333333%;
    flex-basis: 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .md-2 {
    -webkit-flex-basis: 16.6666666667%;
    -moz-flex-basis: 16.6666666667%;
    -ms-flex-preferred-size: 16.6666666667%;
    flex-basis: 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .md-3 {
    -webkit-flex-basis: 25%;
    -moz-flex-basis: 25%;
    -ms-flex-preferred-size: 25%;
    flex-basis: 25%;
    max-width: 25%;
  }
  .md-4 {
    -webkit-flex-basis: 33.3333333333%;
    -moz-flex-basis: 33.3333333333%;
    -ms-flex-preferred-size: 33.3333333333%;
    flex-basis: 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .md-5 {
    -webkit-flex-basis: 41.6666666667%;
    -moz-flex-basis: 41.6666666667%;
    -ms-flex-preferred-size: 41.6666666667%;
    flex-basis: 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .md-6 {
    -webkit-flex-basis: 50%;
    -moz-flex-basis: 50%;
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    max-width: 50%;
  }
  .md-7 {
    -webkit-flex-basis: 58.3333333333%;
    -moz-flex-basis: 58.3333333333%;
    -ms-flex-preferred-size: 58.3333333333%;
    flex-basis: 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .md-8 {
    -webkit-flex-basis: 66.6666666667%;
    -moz-flex-basis: 66.6666666667%;
    -ms-flex-preferred-size: 66.6666666667%;
    flex-basis: 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .md-9 {
    -webkit-flex-basis: 75%;
    -moz-flex-basis: 75%;
    -ms-flex-preferred-size: 75%;
    flex-basis: 75%;
    max-width: 75%;
  }
  .md-10 {
    -webkit-flex-basis: 83.3333333333%;
    -moz-flex-basis: 83.3333333333%;
    -ms-flex-preferred-size: 83.3333333333%;
    flex-basis: 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .md-11 {
    -webkit-flex-basis: 91.6666666667%;
    -moz-flex-basis: 91.6666666667%;
    -ms-flex-preferred-size: 91.6666666667%;
    flex-basis: 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .md-12 {
    -webkit-flex-basis: 100%;
    -moz-flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    max-width: 100%;
  }
  .md-auto {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 0%;
    -moz-box-flex: 1;
    -moz-flex: 1 1 0%;
    -ms-flex: 1 1 0%;
    flex: 1 1 0%;
  }
  .md-offset-1 {
    margin-left: 8.3333333333%;
  }
  .md-offset-2 {
    margin-left: 16.6666666667%;
  }
  .md-offset-3 {
    margin-left: 25%;
  }
  .md-offset-4 {
    margin-left: 33.3333333333%;
  }
  .md-offset-5 {
    margin-left: 41.6666666667%;
  }
  .md-offset-6 {
    margin-left: 50%;
  }
  .md-offset-7 {
    margin-left: 58.3333333333%;
  }
  .md-offset-8 {
    margin-left: 66.6666666667%;
  }
  .md-offset-9 {
    margin-left: 75%;
  }
  .md-offset-10 {
    margin-left: 83.3333333333%;
  }
  .md-offset-11 {
    margin-left: 91.6666666667%;
  }
  .md-pull-1 {
    position: relative;
    right: 8.3333333333%;
  }
  .md-pull-2 {
    position: relative;
    right: 16.6666666667%;
  }
  .md-pull-3 {
    position: relative;
    right: 25%;
  }
  .md-pull-4 {
    position: relative;
    right: 33.3333333333%;
  }
  .md-pull-5 {
    position: relative;
    right: 41.6666666667%;
  }
  .md-pull-6 {
    position: relative;
    right: 50%;
  }
  .md-pull-7 {
    position: relative;
    right: 58.3333333333%;
  }
  .md-pull-8 {
    position: relative;
    right: 66.6666666667%;
  }
  .md-pull-9 {
    position: relative;
    right: 75%;
  }
  .md-pull-10 {
    position: relative;
    right: 83.3333333333%;
  }
  .md-pull-11 {
    position: relative;
    right: 91.6666666667%;
  }
  .md-pull-12 {
    position: relative;
    right: 100%;
  }
  .md-push-1 {
    position: relative;
    left: 8.3333333333%;
  }
  .md-push-2 {
    position: relative;
    left: 16.6666666667%;
  }
  .md-push-3 {
    position: relative;
    left: 25%;
  }
  .md-push-4 {
    position: relative;
    left: 33.3333333333%;
  }
  .md-push-5 {
    position: relative;
    left: 41.6666666667%;
  }
  .md-push-6 {
    position: relative;
    left: 50%;
  }
  .md-push-7 {
    position: relative;
    left: 58.3333333333%;
  }
  .md-push-8 {
    position: relative;
    left: 66.6666666667%;
  }
  .md-push-9 {
    position: relative;
    left: 75%;
  }
  .md-push-10 {
    position: relative;
    left: 83.3333333333%;
  }
  .md-push-11 {
    position: relative;
    left: 91.6666666667%;
  }
  .md-push-12 {
    position: relative;
    left: 100%;
  }
  .md-start {
    margin-right: auto;
  }
  .md-center {
    margin-right: auto;
    margin-left: auto;
  }
  .md-end {
    margin-left: auto;
  }
  .md-top {
    -webkit-align-self: flex-start;
    -moz-align-self: flex-start;
    -ms-flex-item-align: start;
    align-self: flex-start;
  }
  .md-middle {
    -webkit-align-self: center;
    -moz-align-self: center;
    -ms-flex-item-align: center;
    align-self: center;
  }
  .md-bottom {
    -webkit-align-self: flex-end;
    -moz-align-self: flex-end;
    -ms-flex-item-align: end;
    align-self: flex-end;
  }
  .md-cell {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -moz-box-flex: 1;
    -moz-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
}
@media only screen and (min-width: 992px) {
  .lg-1 {
    -webkit-flex-basis: 8.3333333333%;
    -moz-flex-basis: 8.3333333333%;
    -ms-flex-preferred-size: 8.3333333333%;
    flex-basis: 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .lg-2 {
    -webkit-flex-basis: 16.6666666667%;
    -moz-flex-basis: 16.6666666667%;
    -ms-flex-preferred-size: 16.6666666667%;
    flex-basis: 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .lg-3 {
    -webkit-flex-basis: 25%;
    -moz-flex-basis: 25%;
    -ms-flex-preferred-size: 25%;
    flex-basis: 25%;
    max-width: 25%;
  }
  .lg-4 {
    -webkit-flex-basis: 33.3333333333%;
    -moz-flex-basis: 33.3333333333%;
    -ms-flex-preferred-size: 33.3333333333%;
    flex-basis: 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .lg-5 {
    -webkit-flex-basis: 41.6666666667%;
    -moz-flex-basis: 41.6666666667%;
    -ms-flex-preferred-size: 41.6666666667%;
    flex-basis: 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .lg-6 {
    -webkit-flex-basis: 50%;
    -moz-flex-basis: 50%;
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    max-width: 50%;
  }
  .lg-7 {
    -webkit-flex-basis: 58.3333333333%;
    -moz-flex-basis: 58.3333333333%;
    -ms-flex-preferred-size: 58.3333333333%;
    flex-basis: 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .lg-8 {
    -webkit-flex-basis: 66.6666666667%;
    -moz-flex-basis: 66.6666666667%;
    -ms-flex-preferred-size: 66.6666666667%;
    flex-basis: 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .lg-9 {
    -webkit-flex-basis: 75%;
    -moz-flex-basis: 75%;
    -ms-flex-preferred-size: 75%;
    flex-basis: 75%;
    max-width: 75%;
  }
  .lg-10 {
    -webkit-flex-basis: 83.3333333333%;
    -moz-flex-basis: 83.3333333333%;
    -ms-flex-preferred-size: 83.3333333333%;
    flex-basis: 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .lg-11 {
    -webkit-flex-basis: 91.6666666667%;
    -moz-flex-basis: 91.6666666667%;
    -ms-flex-preferred-size: 91.6666666667%;
    flex-basis: 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .lg-12 {
    -webkit-flex-basis: 100%;
    -moz-flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    max-width: 100%;
  }
  .lg-auto {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 0%;
    -moz-box-flex: 1;
    -moz-flex: 1 1 0%;
    -ms-flex: 1 1 0%;
    flex: 1 1 0%;
  }
  .lg-offset-1 {
    margin-left: 8.3333333333%;
  }
  .lg-offset-2 {
    margin-left: 16.6666666667%;
  }
  .lg-offset-3 {
    margin-left: 25%;
  }
  .lg-offset-4 {
    margin-left: 33.3333333333%;
  }
  .lg-offset-5 {
    margin-left: 41.6666666667%;
  }
  .lg-offset-6 {
    margin-left: 50%;
  }
  .lg-offset-7 {
    margin-left: 58.3333333333%;
  }
  .lg-offset-8 {
    margin-left: 66.6666666667%;
  }
  .lg-offset-9 {
    margin-left: 75%;
  }
  .lg-offset-10 {
    margin-left: 83.3333333333%;
  }
  .lg-offset-11 {
    margin-left: 91.6666666667%;
  }
  .lg-pull-1 {
    position: relative;
    right: 8.3333333333%;
  }
  .lg-pull-2 {
    position: relative;
    right: 16.6666666667%;
  }
  .lg-pull-3 {
    position: relative;
    right: 25%;
  }
  .lg-pull-4 {
    position: relative;
    right: 33.3333333333%;
  }
  .lg-pull-5 {
    position: relative;
    right: 41.6666666667%;
  }
  .lg-pull-6 {
    position: relative;
    right: 50%;
  }
  .lg-pull-7 {
    position: relative;
    right: 58.3333333333%;
  }
  .lg-pull-8 {
    position: relative;
    right: 66.6666666667%;
  }
  .lg-pull-9 {
    position: relative;
    right: 75%;
  }
  .lg-pull-10 {
    position: relative;
    right: 83.3333333333%;
  }
  .lg-pull-11 {
    position: relative;
    right: 91.6666666667%;
  }
  .lg-pull-12 {
    position: relative;
    right: 100%;
  }
  .lg-push-1 {
    position: relative;
    left: 8.3333333333%;
  }
  .lg-push-2 {
    position: relative;
    left: 16.6666666667%;
  }
  .lg-push-3 {
    position: relative;
    left: 25%;
  }
  .lg-push-4 {
    position: relative;
    left: 33.3333333333%;
  }
  .lg-push-5 {
    position: relative;
    left: 41.6666666667%;
  }
  .lg-push-6 {
    position: relative;
    left: 50%;
  }
  .lg-push-7 {
    position: relative;
    left: 58.3333333333%;
  }
  .lg-push-8 {
    position: relative;
    left: 66.6666666667%;
  }
  .lg-push-9 {
    position: relative;
    left: 75%;
  }
  .lg-push-10 {
    position: relative;
    left: 83.3333333333%;
  }
  .lg-push-11 {
    position: relative;
    left: 91.6666666667%;
  }
  .lg-push-12 {
    position: relative;
    left: 100%;
  }
  .lg-start {
    margin-right: auto;
  }
  .lg-center {
    margin-right: auto;
    margin-left: auto;
  }
  .lg-end {
    margin-left: auto;
  }
  .lg-top {
    -webkit-align-self: flex-start;
    -moz-align-self: flex-start;
    -ms-flex-item-align: start;
    align-self: flex-start;
  }
  .lg-middle {
    -webkit-align-self: center;
    -moz-align-self: center;
    -ms-flex-item-align: center;
    align-self: center;
  }
  .lg-bottom {
    -webkit-align-self: flex-end;
    -moz-align-self: flex-end;
    -ms-flex-item-align: end;
    align-self: flex-end;
  }
  .lg-cell {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -moz-box-flex: 1;
    -moz-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
}
@media only screen and (min-width: 1200px) {
  .xl-1 {
    -webkit-flex-basis: 8.3333333333%;
    -moz-flex-basis: 8.3333333333%;
    -ms-flex-preferred-size: 8.3333333333%;
    flex-basis: 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .xl-2 {
    -webkit-flex-basis: 16.6666666667%;
    -moz-flex-basis: 16.6666666667%;
    -ms-flex-preferred-size: 16.6666666667%;
    flex-basis: 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .xl-3 {
    -webkit-flex-basis: 25%;
    -moz-flex-basis: 25%;
    -ms-flex-preferred-size: 25%;
    flex-basis: 25%;
    max-width: 25%;
  }
  .xl-4 {
    -webkit-flex-basis: 33.3333333333%;
    -moz-flex-basis: 33.3333333333%;
    -ms-flex-preferred-size: 33.3333333333%;
    flex-basis: 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .xl-5 {
    -webkit-flex-basis: 41.6666666667%;
    -moz-flex-basis: 41.6666666667%;
    -ms-flex-preferred-size: 41.6666666667%;
    flex-basis: 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .xl-6 {
    -webkit-flex-basis: 50%;
    -moz-flex-basis: 50%;
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    max-width: 50%;
  }
  .xl-7 {
    -webkit-flex-basis: 58.3333333333%;
    -moz-flex-basis: 58.3333333333%;
    -ms-flex-preferred-size: 58.3333333333%;
    flex-basis: 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .xl-8 {
    -webkit-flex-basis: 66.6666666667%;
    -moz-flex-basis: 66.6666666667%;
    -ms-flex-preferred-size: 66.6666666667%;
    flex-basis: 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .xl-9 {
    -webkit-flex-basis: 75%;
    -moz-flex-basis: 75%;
    -ms-flex-preferred-size: 75%;
    flex-basis: 75%;
    max-width: 75%;
  }
  .xl-10 {
    -webkit-flex-basis: 83.3333333333%;
    -moz-flex-basis: 83.3333333333%;
    -ms-flex-preferred-size: 83.3333333333%;
    flex-basis: 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .xl-11 {
    -webkit-flex-basis: 91.6666666667%;
    -moz-flex-basis: 91.6666666667%;
    -ms-flex-preferred-size: 91.6666666667%;
    flex-basis: 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .xl-12 {
    -webkit-flex-basis: 100%;
    -moz-flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    max-width: 100%;
  }
  .xl-auto {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 0%;
    -moz-box-flex: 1;
    -moz-flex: 1 1 0%;
    -ms-flex: 1 1 0%;
    flex: 1 1 0%;
  }
  .xl-offset-1 {
    margin-left: 8.3333333333%;
  }
  .xl-offset-2 {
    margin-left: 16.6666666667%;
  }
  .xl-offset-3 {
    margin-left: 25%;
  }
  .xl-offset-4 {
    margin-left: 33.3333333333%;
  }
  .xl-offset-5 {
    margin-left: 41.6666666667%;
  }
  .xl-offset-6 {
    margin-left: 50%;
  }
  .xl-offset-7 {
    margin-left: 58.3333333333%;
  }
  .xl-offset-8 {
    margin-left: 66.6666666667%;
  }
  .xl-offset-9 {
    margin-left: 75%;
  }
  .xl-offset-10 {
    margin-left: 83.3333333333%;
  }
  .xl-offset-11 {
    margin-left: 91.6666666667%;
  }
  .xl-pull-1 {
    position: relative;
    right: 8.3333333333%;
  }
  .xl-pull-2 {
    position: relative;
    right: 16.6666666667%;
  }
  .xl-pull-3 {
    position: relative;
    right: 25%;
  }
  .xl-pull-4 {
    position: relative;
    right: 33.3333333333%;
  }
  .xl-pull-5 {
    position: relative;
    right: 41.6666666667%;
  }
  .xl-pull-6 {
    position: relative;
    right: 50%;
  }
  .xl-pull-7 {
    position: relative;
    right: 58.3333333333%;
  }
  .xl-pull-8 {
    position: relative;
    right: 66.6666666667%;
  }
  .xl-pull-9 {
    position: relative;
    right: 75%;
  }
  .xl-pull-10 {
    position: relative;
    right: 83.3333333333%;
  }
  .xl-pull-11 {
    position: relative;
    right: 91.6666666667%;
  }
  .xl-pull-12 {
    position: relative;
    right: 100%;
  }
  .xl-push-1 {
    position: relative;
    left: 8.3333333333%;
  }
  .xl-push-2 {
    position: relative;
    left: 16.6666666667%;
  }
  .xl-push-3 {
    position: relative;
    left: 25%;
  }
  .xl-push-4 {
    position: relative;
    left: 33.3333333333%;
  }
  .xl-push-5 {
    position: relative;
    left: 41.6666666667%;
  }
  .xl-push-6 {
    position: relative;
    left: 50%;
  }
  .xl-push-7 {
    position: relative;
    left: 58.3333333333%;
  }
  .xl-push-8 {
    position: relative;
    left: 66.6666666667%;
  }
  .xl-push-9 {
    position: relative;
    left: 75%;
  }
  .xl-push-10 {
    position: relative;
    left: 83.3333333333%;
  }
  .xl-push-11 {
    position: relative;
    left: 91.6666666667%;
  }
  .xl-push-12 {
    position: relative;
    left: 100%;
  }
  .xl-start {
    margin-right: auto;
  }
  .xl-center {
    margin-right: auto;
    margin-left: auto;
  }
  .xl-end {
    margin-left: auto;
  }
  .xl-top {
    -webkit-align-self: flex-start;
    -moz-align-self: flex-start;
    -ms-flex-item-align: start;
    align-self: flex-start;
  }
  .xl-middle {
    -webkit-align-self: center;
    -moz-align-self: center;
    -ms-flex-item-align: center;
    align-self: center;
  }
  .xl-bottom {
    -webkit-align-self: flex-end;
    -moz-align-self: flex-end;
    -ms-flex-item-align: end;
    align-self: flex-end;
  }
  .xl-cell {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -moz-box-flex: 1;
    -moz-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
}
.scs-container {
  height: 100%;
  width: 100%;
}

.content-wrapper {
  height: calc(100% - 48px);
}

.content {
  height: 100%;
}

.scroll-wrapper {
  flex: 1 1 auto;
  flex-direction: column;
  display: flex;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  line-height: 1.5;
}
.scroll-wrapper--sm {
  height: calc(100% - 43px);
}
.scroll-wrapper--xs {
  height: calc(100% - 72px);
}
.scroll-wrapper--xxs {
  height: calc(100% - 199px);
}
.scroll-wrapper--xxxs {
  height: calc(100% - 350px);
}

.scrollbar::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}
.scrollbar::-webkit-scrollbar-track {
  background-color: #eee;
}
.scrollbar::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.1);
  outline: 1px solid slategrey;
  border-radius: 5px;
  outline: none;
  border: none;
}
.scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: lightgray;
}
.scrollbar::-webkit-scrollbar-corner {
  background-color: #eee;
}

.content {
  height: 100%;
}

.header {
  display: flex;
  height: 48px;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  background-color: #007bff;
  color: #ffffff;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.header_item {
  cursor: pointer;
  padding-top: 15px;
  text-align: center;
}
.header_item:nth-child(1) {
  flex: 0 1 35%;
  max-width: 35%;
}
.header_item:nth-child(2) {
  flex: 0 1 37%;
  max-width: 37%;
}
.header_item:nth-child(3) {
  flex: 0 1 28%;
  max-width: 28%;
}
.header_item--underline {
  border-bottom: 3px solid rgba(0, 0, 0, 0.75);
}

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.warning {
  margin-top: 1rem;
  color: red;
}

.badge {
  font-size: 10px;
  background-color: #e9e9e9;
  padding: 5px;
  display: inline-block;
  font-weight: 400;
  line-height: 0.65;
  text-align: center;
  white-space: nowrap;
  border-radius: 0.25rem;
  color: #6B6B6B;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  cursor: pointer;
}
.badge--bold {
  font-weight: 700;
}
.badge--blue {
  color: #fff;
  background-color: #007bff;
}

.button {
  position: relative;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  text-decoration: none;
  font-size: 14px;
  font-weight: 400;
  padding: 4px;
}
.button:hover {
  text-decoration: none;
  background-color: rgba(65, 108, 150, 0.05);
}
.button:focus {
  outline: none;
}
.button:disabled, .button[disabled] {
  cursor: no-drop;
  opacity: 0.7;
}
.button--large {
  padding: 6px 10px;
}
.button--has-addon {
  padding-right: 30px;
  padding-left: 15px;
}
.button_icon {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
}

.button-primary {
  border: 0;
  border-radius: 3px;
  cursor: pointer;
  font-size: 12px;
  font-weight: bold;
  align-items: center;
  justify-content: center;
  display: inline-flex;
  line-height: 1.5;
  padding: 0.75rem 2.5rem;
  background-color: #007bff;
  border-color: #007bff;
  color: white;
}
.button-primary:hover {
  text-decoration: none;
}
.button-primary:focus {
  outline: none;
}
.button-primary:disabled, .button-primary[disabled] {
  cursor: no-drop;
  opacity: 0.7;
}
.button-primary--white {
  color: #333;
  background-color: #fff;
  border: 1px solid #ced4da;
  padding: 0.375rem 0.5rem;
}
.button-primary--grey {
  color: #333;
  background-color: #ced4da;
}
.button-primary--transparent {
  background-color: transparent;
}
.button-primary--transparent:hover {
  background-color: rgba(65, 108, 150, 0.15);
}
.button-primary--search {
  color: #333;
  background-color: #fff;
  border: 1px solid #ced4da;
  padding: 0;
}
.button-primary--sm {
  padding: 0.375rem 0.75rem;
}
.button-primary--upload {
  color: #333;
  background-color: #fff;
  border: 1px solid #545b62;
  margin: 10px 0;
  padding: 0.375rem 2rem;
}
.button-primary--upload:hover {
  color: #fff;
  background-color: #5a6268;
}
.button-primary--upload > fa-icon {
  width: 20px;
  height: auto;
}

.button-dropdown {
  padding: 0;
}
.button-dropdown::after {
  border: solid;
  border-width: 0 1px 1px 0;
  display: inline-block;
  padding: 2px;
  margin-left: 7px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}
.button-dropdown--active {
  color: #fff;
  background-color: #545b62;
  border-color: #4e555b;
}
.button-dropdown_icon {
  padding: 0.375rem 0.5rem;
}

.checkbox_input {
  display: none;
}
.checkbox_label {
  display: block;
  cursor: pointer;
  margin-bottom: 0;
  padding-left: 20px;
  font-size: 13px;
  position: relative;
}
.checkbox_label::before, .checkbox_label::after {
  content: "";
  position: absolute;
}
.checkbox_label--error::before {
  border-color: #a94442;
}
.checkbox_input + .checkbox_label::before {
  background-color: white;
  border-width: 1px;
  border-style: solid;
  border-radius: 2px;
  display: block;
  height: 13px;
  width: 13px;
  top: 2px;
  left: 0;
  transition: border-color 0.15s 0s ease-in-out;
}
.checkbox_input + .checkbox_label:hover::before {
  border-color: black;
}
.checkbox_input:checked + .checkbox_label::before {
  background-color: #d3d3d3;
  border-width: 1px;
  transition: background-color 0.05s 0s ease-in-out;
}
.checkbox_input:checked + .checkbox_label:hover::before {
  background-color: rgb(143, 156, 143);
}
.checkbox_input:checked + .checkbox_label::after {
  border: solid black;
  border-width: 0 2px 2px 0;
  height: 10px;
  width: 6px;
  top: 2px;
  left: 4px;
  transform: rotate(45deg);
}

.drawer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 10px;
  text-align: center;
  background-color: #fff;
  border-top: 1px solid #d3d3d3;
}
.drawer_header {
  font-size: 13px;
  text-align: center;
  padding-bottom: 10px;
}
.drawer--panel {
  text-align: left;
  z-index: 2;
}
.drawer--panel > *:not(:first-child) {
  margin-left: 10px;
}

.scs-dropdown {
  position: absolute;
  z-index: 9;
  top: 33px;
  left: 0;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  max-width: 500px;
  max-height: 500px;
  overflow-x: hidden;
  overflow-y: auto;
  box-shadow: rgba(0, 0, 0, 0.4) 0 6px 20px;
  border: 0;
  border-radius: 5px;
  color: #000;
  font-size: 12px;
  padding: 7px 0;
}
.scs-dropdown--right {
  top: 37px;
  right: 0;
  left: auto;
  max-height: 400px;
  width: 280px;
}
.scs-dropdown_item {
  width: 100%;
  max-width: 250px;
  padding: 0.25rem 1.5rem;
  font-weight: 400;
  color: #212529;
  background-color: transparent;
  border: 0;
  text-align: left;
  cursor: pointer;
}
.scs-dropdown_item:hover {
  background-color: rgba(65, 108, 150, 0.1);
  color: #000;
}
.scs-dropdown_item--active {
  color: #fff;
  background-color: #007bff;
}
.scs-dropdown_divider {
  border-top: 1px solid #e2e2e2;
  margin: 5px 0;
}
.scs-dropdown_icon {
  margin-right: 10px;
}

.field-error {
  font-size: 11px;
  color: #a94442;
}
.field-error_container {
  min-height: 16px;
  position: absolute;
  left: 0;
  bottom: -15px;
}

.form-group {
  margin-bottom: 14px;
}
.form-group--has-error {
  margin-bottom: 0;
}
.form-group--inline {
  display: flex;
  align-items: center;
}
.form-group--inline > *:first-child {
  flex: 0 0 auto;
  margin-right: 5px;
  margin-bottom: 16px;
}
.form-group--inline > *:not(:first-child) {
  flex: 1 1 auto;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 4px;
  font-size: 13px;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 2px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s;
}
.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
}
.form-control::placeholder {
  color: #9B9B9B;
}
.form-control--disabled, .form-control[disabled] {
  opacity: 0.5;
}
.form-control--error {
  border: 2px solid #a94442;
}
.form-control--no-padding {
  padding: 0;
}

.form-label {
  display: inline-block;
  margin-bottom: 0.2rem;
  font-size: 13px;
}

textarea.form-control {
  height: auto;
}

.form-error {
  position: absolute;
  bottom: -15px;
  margin-left: 0 !important;
  left: 0;
}

.panel {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 1;
  overflow-y: auto;
  background-color: white;
  padding: 15px 10px;
}
.panel--sm {
  padding-bottom: 52px;
}
.panel_icon-close--left, .panel_icon-close--right {
  position: absolute;
  top: 5px;
  cursor: pointer;
}
.panel_icon-close--left {
  left: 5px;
}
.panel_icon-close--right {
  right: 5px;
}
.panel_title {
  font-size: 22px;
  line-height: 1.2;
  padding: 12px 0 5px;
}
.panel_button-box {
  display: flex;
  align-items: center;
  padding: 10px 0;
}
.panel_button-box > *:not(:first-child) {
  margin-left: 5px;
}

.label {
  color: #9b9b9b;
  font-size: 11px;
  font-weight: 700;
  text-transform: uppercase;
  margin-top: 5px;
  padding-left: 0.5rem;
}

.list-item {
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 62px;
  font-size: 12px;
  background-color: #ffffff;
  padding: 6px 5px 6px 28px;
}
.list-item:nth-child(even) {
  background-color: #f3f3f3;
}
.list-item--no-box {
  padding-left: 6px;
}
.list-item_title {
  min-height: 14px;
  font-weight: 700;
  line-height: 1.2;
  color: #000000;
  cursor: pointer;
}
.list-item_author-box {
  min-height: 18px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  color: #007ac3;
  white-space: nowrap;
}
.list-item_author-box > *:not(:last-child) {
  margin-right: 6px;
}
.list-item_author:not(:last-of-type)::after {
  content: ";";
  margin-left: -3px;
}
.list-item_journal-box {
  min-height: 18px;
  color: #6B6B6B;
}
.list-item_content {
  color: #2E2E2E;
}
.list-item_checkbox {
  position: absolute;
  background-color: white;
  border-width: 1px;
  border-style: solid;
  border-radius: 2px;
  display: block;
  height: 13px;
  width: 13px;
  top: 50%;
  left: 7px;
  transform: translateY(-50%);
  cursor: pointer;
  transition: border-color 0.15s 0s ease-in-out;
}
.list-item_checkbox:hover {
  border-color: black;
}
.list-item_checkbox--checked {
  background-color: #d3d3d3;
  border-width: 1px;
  transition: background-color 0.05s 0s ease-in-out;
}
.list-item_checkbox--checked:hover {
  background-color: rgb(143, 156, 143);
}
.list-item_checkbox--checked::after {
  content: "";
  position: absolute;
  border: solid black;
  border-width: 0 2px 2px 0;
  height: 10px;
  width: 6px;
  top: -1px;
  left: 2px;
  transform: rotate(45deg);
}

.loader {
  text-align: center;
}
.loader_overlay {
  position: relative;
  height: 100%;
}
.loader_more-container {
  width: 100%;
  display: block;
  padding: 20px 0;
}
.loader_circle {
  width: 18px;
  height: 18px;
  opacity: 0.9;
  background-color: #333;
  border-radius: 100%;
  display: inline-block;
  animation: bouncedelay 1.4s infinite ease-in-out both;
}
.loader_circle:nth-of-type(3) {
  animation-delay: -0.3s;
}
.loader_circle:nth-of-type(2) {
  animation-delay: -0.15s;
}
.loader_circle--sm {
  width: 13px;
  height: 13px;
}

@keyframes bouncedelay {
  0%, 80%, 100% {
    transform: scale(0);
  }
  40% {
    transform: scale(0.9);
  }
}
.matcher_container {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
}
.matcher_header {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  background-color: #007bff;
  color: #ffffff;
}
.matcher_info-box {
  display: flex;
  align-items: center;
  border-bottom: 1px solid lightgrey;
  padding: 10px 15px;
  color: #007bff;
}
.matcher_info-box > *:last-child {
  color: black;
  font-size: 12px;
  margin-left: 12px;
}
.matcher_title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: black;
  font-size: 12px;
  font-weight: 700;
  text-align: center;
  padding: 8px 15px;
}
.matcher_item-container {
  padding: 6px;
  margin: 0 15px 10px 15px;
  background-color: white;
  font-size: 12px;
  max-height: 105px;
}
.matcher_item-row {
  display: flex;
  align-items: start;
}
.matcher_item-row:not(:last-child) {
  margin-bottom: 3px;
}
.matcher_item-row > *:first-child {
  font-weight: bold;
  flex: 0 0 40px;
  margin-right: 10px;
}
.matcher_button {
  position: absolute;
  bottom: 15px;
  left: 30px;
  right: 30px;
}

.modal_backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 999;
  color: #515151;
}
.modal {
  flex: 0 0 auto;
  margin: 40px auto;
}
.modal_frame {
  width: 450px;
  height: 550px;
  border: none;
  border-radius: 4px;
}
.modal_backdrop--centered {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
  color: #515151;
}
.modal--centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 700px;
  background-color: white;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  padding: 22px 30px;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin-top: 50px;
  padding: 40px;
  z-index: 9999;
  background-color: #ffffff;
}

.options {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding-top: 10px;
}
.options_faded {
  padding: 0 0 10px 10px;
  color: grey;
}

.references-box {
  padding: 10px 5px 6px;
  position: relative;
  height: 150px;
  border-bottom: 1px solid #ddd;
}
.references-box_container {
  position: fixed;
  top: 48px;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 1;
  background-color: #f5f5f5;
}
.references-box_icon {
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
}
.references-box_title {
  font-size: 16px;
  font-weight: 700;
  padding-top: 5px;
}
.references-box_content {
  font-size: 13px;
  padding-top: 9px;
  max-height: 39px;
}
.references-box_link-container {
  font-size: 13px;
  padding: 4px 0 7px;
  max-height: 26px;
}
.references-box_link {
  text-decoration: underline;
  padding-right: 4px;
}
.references-box_form {
  display: flex;
  align-items: center;
  position: relative;
}
.references-box_form > *:last-child {
  margin-left: 5px;
  position: relative;
}
.references-box_input {
  flex: 1 0 0;
}
.references-box_button {
  padding: 8px 5px;
  height: 33px;
  min-width: 90px;
}
.references-box_button::after {
  border: solid;
  content: "";
  border-width: 0 1px 1px 0;
  display: inline-block;
  padding: 2px;
  margin-left: 7px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.search-box_container {
  background-color: #f6f6f6;
  border-bottom: 1px solid #ddd;
  min-height: 43px;
  padding: 5px;
}
.search-box_toolbar {
  display: flex;
  align-items: center;
  width: 100%;
}
.search-box_toolbar > *:not(:first-child) {
  margin-left: 4px;
}
.search-box_bar {
  flex: 1 1 auto;
  position: relative;
  min-height: 32px;
}
.search-box_bar input {
  border: none;
  padding-left: 35px;
  padding-right: 15px;
  width: 100%;
  height: 100%;
  font-size: 14px;
}
.search-box_bar input::placeholder {
  color: #9B9B9B;
}
.search-box_bar input:focus {
  outline: none;
}
.search-box_bar input::-ms-clear {
  display: none;
  height: 0;
  width: 0;
}
.search-box_bar--large input {
  padding-left: 20px;
}
.search-box_icon-search {
  position: absolute;
  fill: #9B9B9B;
  left: 4px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}
.search-box_icon-close {
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}
.search-box_icon-down {
  position: absolute;
  left: 20px;
  top: 0;
  cursor: pointer;
  padding: 4px;
}
.search-box_icon-down:after {
  border: solid;
  content: "";
  border-width: 0 1px 1px 0;
  display: inline-block;
  padding: 2px;
  margin-left: 7px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.select-box_container {
  display: flex;
  align-items: center;
  padding: 0 10px;
}
.select-box_container > *:not(:last-child) {
  margin-right: 10px;
}

.splash {
  position: fixed;
  bottom: 0;
  top: 0;
  left: 0;
  width: 100%;
  overflow-y: scroll;
  padding: 10px;
  text-align: center;
  font-size: 14px;
  background-color: white;
}
.splash_logo > img {
  padding-top: 10px;
  width: 170px;
}
.splash_tutorial > img {
  cursor: pointer;
  width: 250px;
}

.style-box {
  padding: 10px;
  position: relative;
}
.style-box_icon {
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
}
.style-box_title {
  font-size: 16px;
  font-weight: 700;
  padding-top: 5px;
}
.style-box_content {
  font-size: 13px;
  padding: 9px 0;
}
.style-box_list {
  display: flex;
  align-items: center;
}
.style-box_list:not(:last-child) {
  padding-bottom: 5px;
}
.style-box_list-icon {
  font-size: 12px;
  margin-left: 5px;
  margin-top: 1px;
  cursor: pointer;
}

.text-list_item, .text-list_item--arrow, .text-list_item--arrow-link {
  list-style: none;
  position: relative;
  margin-left: 18px;
}
.text-list_item::before {
  content: "";
  position: absolute;
  left: -15px;
  top: 8px;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: black;
}
.text-list_item--arrow::before {
  content: "→";
  position: absolute;
  left: -18px;
}
.text-list_item--arrow-link::before {
  content: "→";
  position: absolute;
  left: -18px;
  color: #0066FF;
}

.toast {
  border-radius: 4px;
  padding: 30px 15px;
  font-weight: 500;
  position: fixed;
  top: 20px;
  right: 15px;
  left: 15px;
  z-index: 9999;
  transform: translateX(150%);
  transition: transform 0.3s ease-in-out;
}
.toast--visible {
  transform: translateX(0);
}
.toast--success {
  background-color: #8ac18a;
  color: #fff;
}
.toast--error {
  background-color: #ffa500;
  color: #fff;
}
.toast--fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  width: auto;
  border-radius: 0;
}
.toast--centered {
  top: 50%;
  transform: translateY(-50%);
}
.toast_icon {
  position: absolute;
  top: 5px;
  right: 10px;
  cursor: pointer;
}
.toast_button-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-transform: uppercase;
  padding: 25px 5px 0 5px;
}

.token_container {
  max-height: 87px;
  overflow-y: auto;
  position: relative;
}
.token_item {
  display: inline-block;
  padding: 4px;
  margin: 2px 4px;
  font-size: 11px;
  color: #4A4A4A;
  border-radius: 5px;
  border: 1px solid #000000;
  background-color: #ffffff;
  user-select: none;
  cursor: pointer;
  transition: background-color 200ms linear;
  white-space: nowrap;
}
.token_item > *:not(:first-child) {
  margin-left: 5px;
}
.token_item--single {
  margin: 5px 0 0;
}
.token_item--dark {
  color: #ffffff;
  background-color: #007bff;
}
.token_icon {
  position: absolute;
  cursor: pointer;
  top: 5px;
  right: 5px;
}

.tippy-box[data-animation=fade][data-state=hidden] {
  opacity: 0;
}

[data-tippy-root] {
  max-width: calc(100vw - 100px);
}

.tippy-box {
  position: relative;
  background-color: #333;
  color: #fff;
  border-radius: 4px;
  font-size: 12px;
  line-height: 1.4;
  white-space: normal;
  outline: 0;
  transition-property: transform, visibility, opacity;
}

.tippy-box[data-placement^=top] > .tippy-arrow {
  bottom: 0;
}

.tippy-box[data-placement^=top] > .tippy-arrow:before {
  bottom: -7px;
  left: 0;
  border-width: 8px 8px 0;
  border-top-color: initial;
  transform-origin: center top;
}

.tippy-box[data-placement^=bottom] > .tippy-arrow {
  top: 0;
}

.tippy-box[data-placement^=bottom] > .tippy-arrow:before {
  top: -7px;
  left: 0;
  border-width: 0 8px 8px;
  border-bottom-color: initial;
  transform-origin: center bottom;
}

.tippy-box[data-placement^=left] > .tippy-arrow {
  right: 0;
}

.tippy-box[data-placement^=left] > .tippy-arrow:before {
  border-width: 8px 0 8px 8px;
  border-left-color: initial;
  right: -7px;
  transform-origin: center left;
}

.tippy-box[data-placement^=right] > .tippy-arrow {
  left: 0;
}

.tippy-box[data-placement^=right] > .tippy-arrow:before {
  left: -7px;
  border-width: 8px 8px 8px 0;
  border-right-color: initial;
  transform-origin: center right;
}

.tippy-box[data-inertia][data-state=visible] {
  transition-timing-function: cubic-bezier(0.54, 1.5, 0.38, 1.11);
}

.tippy-arrow {
  width: 16px;
  height: 16px;
  color: #333;
}

.tippy-arrow:before {
  content: "";
  position: absolute;
  border-color: transparent;
  border-style: solid;
}

.tippy-content {
  position: relative;
  padding: 5px 9px;
  z-index: 1;
}

.pointer {
  cursor: pointer;
}

.default-cursor {
  cursor: default;
}

.hidden {
  display: none;
}

.show {
  display: block;
}

.block {
  display: block;
}

.flex {
  display: flex;
  align-items: center;
}

.center-horizontal-vertical {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.center-horizontal-vertical--sm {
  left: 25%;
  transform: translate(0, -50%);
}

.relative {
  position: relative;
}

.block {
  display: block;
}

.margin-bottom-sm {
  margin-bottom: 1rem;
}

.margin-top-sm {
  margin-top: 1rem;
}

.padding-top-md {
  padding-top: 15px;
}

.padding-top-sm {
  padding-top: 10px;
}

.padding-bottom-sm {
  padding-bottom: 10px;
}

.padding-bottom-xxl {
  padding-bottom: 50px;
}

.padding-bottom-xs {
  padding-bottom: 5px;
}

.padding-left-xxs {
  padding-left: 3px;
}

.padding-md {
  padding: 15px;
}

.text--h1 {
  color: #363737;
  font-size: 24px;
  font-weight: 400;
  font-family: "Quicksand", sans-serif;
}

.text--title {
  font-size: 20px;
  font-family: "Quicksand", sans-serif;
  color: #0066FF;
}

.text--subtitle {
  font-size: 18px;
  font-family: "Quicksand", sans-serif;
  color: grey;
}

.text--title-bold {
  font-size: 14px;
  font-weight: 700;
}

.text--title-aside {
  font-size: 18px;
  font-family: "Quicksand", sans-serif;
  color: #363737;
  font-weight: 700;
  margin-bottom: 10px;
}

.text--center {
  text-align: center;
}

.text--sm {
  font-size: 13px;
}

.text--lg {
  font-size: 18px;
}

.text--grey {
  color: grey;
}

.text--link {
  color: #0066FF;
  text-decoration: underline;
}

.ellipsis, .scs-dropdown_item, .list-item_title, .list-item_author-box > *, .list-item_journal-box, .search-box_bar input {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.lowercase {
  text-transform: lowercase;
}

.uppercase {
  text-transform: uppercase;
}

.cdk-drag-placeholder {
  opacity: 0;
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.token_container.cdk-drop-list-dragging .token_item:not(.cdk-drag-placeholder) {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

context-menu-content .dropdown {
  position: relative;
}
context-menu-content .dropdown-menu {
  border-radius: 5px;
  background: #fff;
  border: 0;
  color: #000;
  font-size: 12px;
  max-width: 500px;
  max-height: 500px;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  overflow-x: hidden;
  overflow-y: auto;
  box-shadow: rgba(0, 0, 0, 0.4) 0 6px 20px;
}
context-menu-content .dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  text-decoration: none;
}
context-menu-content .dropdown-item:hover, context-menu-content .dropdown-item:focus {
  color: #16181b;
  text-decoration: none;
  background-color: #f8f9fa;
}
context-menu-content .dropdown-menu .dropdown-item:not(.active):hover {
  background-color: rgba(65, 108, 150, 0.1);
  color: #000;
}